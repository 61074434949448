/**
 *
 * Control
 */

import type { DeviceEnums } from "./_constants";
import {
  devicesDimensions,
  imageDimensions,
  landscapePlacements,
  portraitPlacements,
} from "./_data";

export const maxIndex = 9;

let previousPoint = 0;
let point = -1;
let isZooming: Promise<unknown> | false = false;
let pointCbArray: Function[] = [];

export const addPointCb = (cb: (point?: number) => void) => {
  pointCbArray.push(cb);
};

export const incrementActivePoint = () => {
  previousPoint = point;
  point++;
  if (point > maxIndex) {
    point = 0;
  }
  pointCbArray.forEach((cb) => {
    cb(point);
  });
};

export const decrementActivePoint = () => {
  previousPoint = point;
  point--;
  if (point < 0) {
    point = maxIndex;
  }
  pointCbArray.forEach((cb) => {
    cb(point);
  });
};

export const forceSetPoint = (p: number) => {
  point = p;
  previousPoint = p - 1;
};

export const setPoint = (p: number) => {
  point = p;
};

export const getActivePoint = () => {
  return point;
};

export const getPreviousActivePoint = () => {
  return previousPoint;
};

export const isZoomingActive = () => {
  return !!isZooming;
};

export const setZooming = (value: Promise<unknown>) => {
  isZooming = value;
  value.then((r) => {
    isZooming = false;
  });
};

export const queueAfterZoom = (cb: () => Promise<unknown>) => {
  if (isZooming) {
    isZooming.then((r) => {
      const p = cb();
      setZooming(p);
    });
  } else {
    const p = cb();
    setZooming(p);
  }
};

function debounce(this: any, func: Function, timeout = 300) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const debouncedQueueAfterZoom = debounce(queueAfterZoom, 500);

enum PlacementEnums {
  landscape = "landscape",
  portrait = "portrait",
}

type ViewportData = {
  x: number;
  y: number;
  w: number;
  h: number;
  alignment?: string | undefined;
};

type DeviceViewportData = {
  deviceType: DeviceEnums;
  placement: PlacementEnums;
  width: number;
  height: number;
  viewData: ViewportData[];
};

export const getViewportDetails = (
  currentImageDimensions: { w: number; h: number },
  deviceViewportData: DeviceViewportData,
  idx: number
) => {
  const { h: originalImageHeight, w: originalImageWidth } = imageDimensions;

  const xCorrectionFactor = currentImageDimensions.w / originalImageWidth;
  const yCorrectionFactor = currentImageDimensions.h / originalImageHeight;

  const viewportPosition = deviceViewportData.viewData[idx];

  const width = viewportPosition.w * xCorrectionFactor;
  const height = viewportPosition.h * yCorrectionFactor;
  const left = viewportPosition.x * xCorrectionFactor;
  const right = left + width;
  const bottom = viewportPosition.y * yCorrectionFactor;
  const top = viewportPosition.y * yCorrectionFactor - height;

  return {
    imageWidth: currentImageDimensions.w,
    imageHeight: currentImageDimensions.h,
    deviceWidth: deviceViewportData.width,
    deviceHeight: deviceViewportData.height,
    top,
    bottom,
    left,
    right,
    width,
    height,
    middleX: left + width / 2,
    middleY: top + height / 2,
    alignment: viewportPosition.alignment,
    zoom: originalImageHeight / viewportPosition.h,
  };
};

export const getDeviceViewportsByViewport = (): DeviceViewportData => {
  const windowwidth = window.innerWidth;
  const windowheight = window.innerHeight;

  const placement =
    windowwidth > windowheight
      ? PlacementEnums.landscape
      : PlacementEnums.portrait;

  const placementDevices = Object.entries(devicesDimensions)
    .map(([key, value]) => {
      if (value.available.includes(placement)) {
        return {
          deviceType: key as DeviceEnums,
          placement,
          width:
            placement === PlacementEnums.landscape ? value.width : value.height,
          height:
            placement === PlacementEnums.landscape ? value.height : value.width,
        };
      }
      return null;
    })
    .filter((e) => e !== null)
    .sort((a, b) => a.width - b.width);

  let i = 1;
  let flag = placementDevices[0];
  while (i < placementDevices.length) {
    const placementDevice = placementDevices[i];
    if (placementDevice.width > windowwidth) {
      break;
    }
    flag = placementDevices[i];
    i++;
  }

  const viewData =
    flag.placement === PlacementEnums.landscape
      ? landscapePlacements[flag.deviceType]
      : portraitPlacements[flag.deviceType as keyof typeof portraitPlacements];

  return {
    ...flag,
    viewData: viewData.map((v) => {
      return {
        ...v,
        alignment: v.alignment,
      };
    }),
  };
};

const getStageProgress = (
  progress: number,
  { startAt, endAt }: { startAt: number; endAt: number }
) => {
  return Math.min(1, Math.max(0, (progress - startAt) / (endAt - startAt)));
};

export const getAnimations = (
  viewport: ReturnType<typeof getViewportDetails>,
  current: {
    scale: number;
    scrollX: number;
    scrollY: number;
    containerHeight: number;
    containerWidth: number;
  },
  _durationOverride?: number
) => {
  const zoomInDistance = viewport.zoom - 1;
  const zoomOutDistance = current.scale - 1;
  const sameZoomDistance =
    zoomInDistance.toFixed(3) === zoomOutDistance.toFixed(3);
  const totalZoomDistance =
    Math.abs(zoomInDistance) + Math.abs(zoomOutDistance);

  const scrollXDistance =
    viewport.middleX - (current.scrollX + current.containerWidth / 2);
  const scrollYDistance = viewport.top - current.scrollY;
  const totalScrollDistance = Math.max(scrollYDistance, scrollXDistance);

  // total duration determined either by scroll, or by zoom.
  const scrollDuration = totalScrollDistance * 1;
  const zoomDuration = totalZoomDistance * 30;

  const duration = _durationOverride
    ? _durationOverride
    : Math.max(scrollDuration, zoomDuration, 1000);

  let scrollFlags: {
    x: number | null;
    y: number | null;
  } = {
    x: null,
    y: null,
  };

  return {
    duration,
    initialiseZoomAtPos(target: HTMLElement) {
      target.style.transformOrigin = `${viewport.middleX}px ${Math.max(viewport.top, 0)}px`;
      target.style.transform = `scale(${viewport.zoom})`;
    },
    initialiseScrollAtPos(target: HTMLElement) {
      target.scrollLeft = scrollXDistance;
      target.scrollTop = scrollYDistance;
    },
    animateZoomIn(target: HTMLElement, progress: number) {
      if (sameZoomDistance) return;
      const _progress = getStageProgress(progress, {
        startAt: 2 / 3,
        endAt: 1,
      });

      if (_progress) {
        target.style.transformOrigin = `${viewport.middleX}px ${viewport.top}px`;
        const scale = Math.min(
          1 + zoomInDistance,
          1 + zoomInDistance * _progress
        );
        target.style.transform = `scale(${scale})`;
      }
    },
    animateZoomOut(target: HTMLElement, progress: number) {
      if (sameZoomDistance) return;
      const __progress = getStageProgress(progress, {
        startAt: 0,
        endAt: 1 / 3,
      });

      if (__progress) {
        target.style.transformOrigin = `${viewport.middleX}px 100%`;
        const scale = Math.max(1, current.scale - zoomOutDistance * __progress);
        target.style.transform = `scale(${scale})`;
      }
    },
    animateOpacityInLinear(target: HTMLElement, progress: number) {
      if (progress) {
        target.style.opacity = String(progress);
      }
    },
    animateOpacityIn(target: HTMLElement, progress: number) {
      const _progress = getStageProgress(progress, {
        startAt: 2 / 3,
        endAt: 1,
      });

      if (_progress) {
        target.style.zIndex = `999`;
        target.style.direction = "block";
        if (viewport.alignment) {
          viewport.alignment.split(" ").forEach((v) => {
            target.classList.add(v);
          });
        }
        target.style.opacity = String(_progress);
      }
    },
    animateOpacityOutLinear(target: HTMLElement, progress: number) {
      if (target.style.opacity === "0") return;

      if (progress) {
        const nett = 1 - progress;
        target.style.opacity = String(nett < 0.95 ? 0 : nett);
      }
    },
    animateOpacityOut(target: HTMLElement, progress: number) {
      const __progress = getStageProgress(progress, {
        startAt: 0,
        endAt: 1 / 3,
      });

      if (target.style.opacity === "0") return;

      if (__progress) {
        const nett = 1 - __progress;
        target.style.opacity = String(nett < 0.95 ? 0 : nett);
        if (nett < 0.95) {
          target.style.zIndex = "0";
          target.style.direction = "none";
        }
      }
    },
    animateScroll(target: HTMLElement, progress: number) {
      if (scrollFlags.x === null) {
        scrollFlags.x = target.scrollLeft || 0;
      }
      if (scrollFlags.y === null) {
        scrollFlags.y = target.scrollTop || 0;
      }

      target.scrollLeft =
        scrollFlags.x + scrollXDistance * easeOutSine(progress);

      target.scrollTop =
        scrollFlags.y + scrollYDistance * easeOutSine(progress);

      if (progress === 1) {
        scrollFlags.x = null;
        scrollFlags.y = null;
      }
    },
  };
};

/**
 *
 * Getters
 */

export const getImage = (document: Document) => {
  const image = document.querySelector("[data-bg-mural]");
  if (image === null || !(image instanceof HTMLElement)) {
    throw Error("Mural not found");
  }
  return image;
};

export const getImageContainer = (document: Document) => {
  const imageContainer = document.querySelector("[data-bg-mural-container]");
  if (imageContainer === null || !(imageContainer instanceof HTMLElement)) {
    throw Error("Image container not found");
  }
  return imageContainer;
};

export const getImageSection = (document: Document, idx: number) => {
  const imageContainer = getImageContainer(document);

  const images = imageContainer.querySelectorAll(
    `[data-bg-mural-section="${idx}"]`
  );

  const _image = [...images].filter((el) => {
    var style = window.getComputedStyle(el);
    return style.display !== "none";
  });

  const image = _image[0];

  if (image === null || !(image instanceof HTMLElement)) {
    throw Error(`Image for ${idx} not found`);
  }
  return image;
};

export const getAllSectionContent = (document: Document) => {
  const images = document.querySelectorAll(`[data-bg-mural-content]`);

  const _image = [...images].filter((el) => {
    var style = window.getComputedStyle(el);
    return style.display !== "none";
  });

  return _image;
};

export const getImageSectionContent = (document: Document, idx: number) => {
  const images = document.querySelectorAll(`[data-bg-mural-content="${idx}"]`);

  const _image = [...images].filter((el) => {
    var style = window.getComputedStyle(el);
    return style.display !== "none";
  });

  const image = _image[0];

  if (image === null || !(image instanceof HTMLElement)) {
    throw Error(`Image content for ${idx} not found`);
  }
  return image;
};

export const getCharactersContainer = (document: Document) => {
  const charactersContainers = document.querySelectorAll(".characters");

  const _characterContainer = [...charactersContainers].filter((el) => {
    var style = window.getComputedStyle(el);
    return style.display !== "none";
  });

  const characterContainer = _characterContainer[0];

  if (
    characterContainer === null ||
    !(characterContainer instanceof HTMLElement)
  ) {
    throw Error("CharacterContainer not found");
  }

  return characterContainer;
};

export const getCharacters = (document: Document) => {
  const charactersContainers = getCharactersContainer(document);

  const characters = charactersContainers.querySelectorAll(".inner");

  return [...characters].filter((e): e is HTMLElement => true);
};

export const getHero = (document: Document) => {
  const heroContainer = document.querySelector("#hero-full");

  if (heroContainer === null || !(heroContainer instanceof HTMLElement)) {
    throw Error("HeroContainer not found");
  }

  return heroContainer;
};

/**
 *
 * Utilities
 */

export function getScaleValue(elem: HTMLElement) {
  const matrixStr = window.getComputedStyle(elem).transform;
  const match = matrixStr.match(/matrix\(([\d.]+),/);
  return match ? parseFloat(match[1]) : null;
}

export function getRotationValues(transform: string) {
  const match = transform.match(/rotateY\((-?\d+(\.\d+)?)deg\)/);
  return match ? parseInt(match[1]) : 0;
}

function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2);
}

// Ease-out function (decelerates towards the end)
export function easeOutCubic(t: number) {
  return 1 - Math.pow(1 - t, 3);
}

function easeInCirc(x: number): number {
  return 1 - Math.sqrt(1 - Math.pow(x, 2));
}

// Ease-in function (accelerates at the start)
export function easeInCubic(t: number) {
  return t * t * t;
}

function easeInOutSine(x: number): number {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

export function getPos(el: HTMLElement) {
  return {
    x: el.getAttribute("data-x"),
    y: el.getAttribute("data-y"),
    w: el.getAttribute("data-w"),
    h: el.getAttribute("data-h"),
  };
}
export function isSamePos(prev: HTMLElement, target: HTMLElement) {
  if (!prev) return false;
  const prevPos = getPos(prev);
  const targetPos = getPos(target);
  return (
    prevPos.x === targetPos.x &&
    prevPos.y === targetPos.y &&
    prevPos.w === targetPos.w &&
    prevPos.h === targetPos.h
  );
}

export function animateZoomAndScroll(
  this: Document,
  opts: { index: number; previousIndex: number; duration?: number },
  complete: (arg: any) => void
) {
  const { index, previousIndex, duration: _durationOverride } = opts;

  const document = this;
  const container = getImageContainer(document);
  const image = getImage(document);
  const allContent = getAllSectionContent(document);

  const deviceViewports = getDeviceViewportsByViewport();
  const viewportDetails = getViewportDetails(
    { w: image.clientWidth, h: image.clientHeight },
    deviceViewports,
    index
  );

  const hero = getHero(document);

  const characters = getCharactersContainer(document);
  const allCharacters = getCharacters(document);

  const { duration, ...animationFns } = getAnimations(
    viewportDetails,
    {
      scale: getScaleValue(image) || 1,
      scrollX: container.scrollLeft,
      scrollY: container.scrollTop,
      containerHeight: container.clientHeight,
      containerWidth: container.clientWidth,
    },
    _durationOverride
  );

  const startTime = performance.now();

  function animate() {
    const now = performance.now();
    const elapsed = now - startTime;
    const progress = Math.min(elapsed / duration, 1);

    const {
      animateZoomIn,
      animateZoomOut,
      animateOpacityIn,
      animateOpacityOut,
      animateScroll,
      animateOpacityInLinear,
      animateOpacityOutLinear,
    } = animationFns;

    animateZoomOut(image, progress);
    animateZoomIn(image, progress);

    allContent.forEach((content) => {
      if (!(content instanceof HTMLElement)) {
        return;
      }
      if (content.dataset.bgMuralContent === `${index}`) {
        animateOpacityIn(content, progress);
      } else {
        if (content.style.opacity) animateOpacityOut(content, progress);
        animateOpacityOut(hero, progress);
      }
    });

    animateScroll(container, progress);

    // Apply rotations where required
    if (index > 7) {
      const _index = index - 8;
      const _prevIndex = Math.max(0, previousIndex - 7);
      const startContainerRotation = _prevIndex * 120;
      const endContainerRotation = _index * 120 + 120;

      const containerRotationDelta = Math.round(
        (endContainerRotation - startContainerRotation) *
          easeInOutSine(progress)
      );
      const currentContainerRotation = getRotationValues(
        characters.style.transform
      );

      const currentActiveCharacter =
        2 - Math.floor((startContainerRotation / 120) % 2);

      if (currentContainerRotation !== startContainerRotation) {
        characters.style.transform = `rotateY(${startContainerRotation}deg)`;
      }

      characters.style.transform = `rotateY(${startContainerRotation + containerRotationDelta}deg)`;

      allCharacters.forEach((character, idx) => {
        const startCharacterRotation = -startContainerRotation - idx * 120;

        const endCharacterRotation = -endContainerRotation - idx * 120;

        const characterRotationDelta = Math.round(
          (endCharacterRotation - startCharacterRotation) *
            easeInOutSine(progress)
        );

        const back = character.querySelector(".behind");
        const front = character.querySelector(".front");

        const characterIdx = Number(character.dataset.idx);

        if (back instanceof HTMLElement && front instanceof HTMLElement) {
          if (currentActiveCharacter === characterIdx) {
            animateOpacityOutLinear(back, easeInCirc(progress));
          } else if (parseFloat(back.style.opacity) < 0.95) {
            animateOpacityInLinear(back, easeOutCubic(progress));
          }
        }

        character.style.transform = `rotateY(${startCharacterRotation + characterRotationDelta}deg)`;
      });
    }

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      complete(true);
    }
  }

  requestAnimationFrame(animate);
}

export function initialise(this: Document) {
  const document = this;
  const container = getImageContainer(document);
  const image = getImage(document);
  const deviceViewports = getDeviceViewportsByViewport();
  const viewportDetails = getViewportDetails(
    { w: image.clientWidth, h: image.clientHeight },
    deviceViewports,
    0
  );

  const animationFns = getAnimations(viewportDetails, {
    scale: getScaleValue(image) || 1,
    scrollX: container.scrollLeft,
    scrollY: container.scrollTop,
    containerHeight: container.clientHeight,
    containerWidth: container.clientWidth,
  });
  const { initialiseScrollAtPos, initialiseZoomAtPos } = animationFns;
  initialiseZoomAtPos(image);
  initialiseScrollAtPos(container);
}

export function animateZoomAndScrollPromise(
  this: Document,
  opts: { index: number; previousIndex: number; duration?: number }
) {
  return new Promise((resolve) => {
    animateZoomAndScroll.call(this, opts, resolve);
  });
}
