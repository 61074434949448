export enum DeviceEnums {
  desktop = "desktop",
  tablet = "tablet",
  mobile = "mobile",
}

enum PlacementEnums {
  landscape = "landscape",
  portrait = "portrait",
}
