import { DeviceEnums } from "./_constants";

export const content = [
  {
    heading: "The Land of Enmei.",
    paragraph:
      "A realm where ancient power courses through every breeze and whisper of the earth.",
  },
  {
    paragraph:
      "Often a harmonious place, where elements transcend mere natural forces; they are the lifeblood of existence, channeled through the sacred art of Jutsu.",
  },
  {
    heading: "But now, something stirs.",
    paragraph:
      "Disturbances ripple across Enmei. Plants in the verdant woods grow rampant, unchecked. Storms rage over the seas with relentless fury.",
  },
  {
    heading: "The Land Trembles.",
    paragraph:
      "The sky darkens. Amidst this chaos, a hero's journey unfolds. From mystical groves to tempest-torn shores, a path emerges for unknown champions yet to rise, harbingers of harmony.",
  },
  {
    heading: "The Tribes of Enmei.",
    paragraph:
      "For generations, tribes have thrived, each attuned to an essence, each living in a delicate balance with forces they harness. But a land in turmoil sows seeds of conflict.",
    cta: {
      link: "/clans",
      text: "Discover Clans",
    },
  },
  {
    heading: "The Essences.",
    paragraph:
      "Verdant, Storm, Ember, Tidal, and Astral. These are the essences that shape the fabric of the universe, and in Enmei the balance is amiss.",
    cta: {
      link: "/essences",
      text: "Discover Essences",
    },
  },
  {
    heading: "Will you answer the call?",
    paragraph:
      "Destiny calls for a hero to traverse these altered expanses, to uncover hidden truths, and to wield the profound might of Jutsu.",
  },
  {
    heading: "How it Works",
    subheading: "Stage 1: Collect",
    paragraph:
      "Obtain $JUTSU tokens to start collecting and to build your deck. For each whole $JUTSU token you hold, you hold a Jutsu Playing Card.",
  },
  {
    heading: "How it Works",
    subheading: "Stage 2: Reroll",
    paragraph:
      "Lock cards you want to keep and battle with. Reroll your unwanted cards for fresh new cards anytime.",
  },
  {
    heading: "How it Works",
    subheading: "Stage 3: Battle",
    paragraph:
      " Compile a deck from your collection, and lead your warriors into battle in our first game - Tactical Card Game “Echoes of Enmei”. Coming Soon.",
    cta: {
      link: "/play",
      text: "Play",
    },
  },
];

export const landscapePlacements = {
  [DeviceEnums.desktop]: [
    {
      x: 556,
      y: 1898,
      w: 2587.95,
      h: 1840.32,
    },
    {
      x: 4388,
      y: 2160,
      w: 2542.42,
      h: 1807.94,
    },
    {
      x: 9762,
      y: 1812,
      w: 2542.42,
      h: 1807.94,
    },
    {
      x: 13048,
      y: 2160,
      w: 2955.09,
      h: 2101.39,
    },
    {
      x: 17886,
      y: 2108,
      w: 2825.34,
      h: 2009.13,
      alignment: "place-self-center self-start xl:place-self-end xl:self-end",
    },
    {
      x: 22044,
      y: 2040,
      w: 2855.11,
      h: 2030.3,
    },
    {
      x: 24388,
      y: 2160,
      w: 3021.32,
      h: 2148.5,
    },
    {
      x: 29052,
      y: 2160,
      w: 3021.32,
      h: 2148.5,
    },
    {
      x: 29052,
      y: 2160,
      w: 3021.32,
      h: 2148.5,
    },
    {
      x: 29052,
      y: 2160,
      w: 3021.32,
      h: 2148.5,
    },
  ],
  [DeviceEnums.tablet]: [
    {
      x: 60,
      y: 1632,
      h: 1625.85,
      w: 2327.66,
      alignment: "place-self-center self-start",
    },
    {
      x: 3938,
      y: 2160,
      h: 1816.04,
      w: 2599.96,
      alignment: "place-self-center self-start",
    },
    {
      x: 9023,
      y: 2094,
      h: 1922.73,
      w: 2752.7,
      alignment: "place-self-center self-end",
    },
    {
      x: 12451,
      y: 2160,
      h: 2013.31,
      w: 2882.38,
      alignment: "place-self-center self-end",
    },
    {
      x: 17820,
      y: 2160,
      h: 2013.31,
      w: 2882.38,
      alignment: "place-self-center self-start",
    },
    {
      x: 22056,
      y: 1852,
      h: 1854.36,
      w: 2655.5,
      alignment: "place-self-center self-end",
    },
    {
      x: 24220,
      y: 2148,
      h: 2148.28,
      w: 3076.39,
      alignment: "place-self-center self-end",
    },
    {
      x: 29000,
      y: 2160,
      h: 2155.77,
      w: 3087.13,
      alignment: "place-self-center self-start",
    },
    {
      x: 29000,
      y: 2160,
      h: 2155.77,
      w: 3087.13,
      alignment: "place-self-center self-start",
    },
    {
      x: 29000,
      y: 2160,
      h: 2155.77,
      w: 3087.13,
      alignment: "place-self-center self-start",
    },
  ],
  [DeviceEnums.mobile]: [
    {
      x: 628,
      y: 1635,
      w: 3539.77,
      h: 1635.67,
      alignment: "place-self-center self-start",
    },
    {
      x: 4530,
      y: 2160,
      w: 4205.51,
      h: 1943.81,
      alignment: "place-self-center self-start",
    },
    {
      x: 9753,
      y: 1730,
      w: 3744.29,
      h: 1730.18,
      alignment: "place-self-center self-end",
    },
    {
      x: 13174,
      y: 2160,
      w: 4210.61,
      h: 1945.66,
      alignment: "place-self-center self-start",
    },
    {
      x: 15772,
      y: 2160,
      w: 4428.63,
      h: 2046.04,
      alignment: "place-self-center self-start",
    },
    {
      x: 20279,
      y: 1771,
      w: 3835.99,
      h: 1771.67,
      alignment: "place-self-center self-end",
    },
    {
      x: 24708,
      y: 2011,
      w: 4352.03,
      h: 2011.01,
      alignment: "place-self-center self-start",
    },
    {
      x: 29190.78,
      y: 1957,
      w: 2756.24,
      h: 1274.19,
      alignment: "place-self-center self-start",
    },
    {
      x: 29190.78,
      y: 1957,
      w: 2756.24,
      h: 1274.19,
      alignment: "place-self-center self-start",
    },
    {
      x: 29190.78,
      y: 1957,
      w: 2756.24,
      h: 1274.19,
      alignment: "place-self-center self-start",
    },
  ],
};

export const portraitPlacements = {
  [DeviceEnums.tablet]: [
    {
      x: 570,
      y: 1836,
      w: 1282.74,
      h: 1836.45,
      alignment: "place-self-center self-start",
    },
    {
      x: 4533,
      y: 2160,
      w: 1391.53,
      h: 1992.19,
      alignment: "place-self-center self-start",
    },
    {
      x: 9835,
      y: 2160,
      w: 1421.38,
      h: 2034.92,
      alignment: "place-self-center self-end",
    },
    {
      x: 12862,
      y: 2038,
      w: 1425.1,
      h: 2040.25,
      alignment: "place-self-center self-start",
    },
    {
      x: 18303,
      y: 2160,
      w: 2040.25,
      h: 2040.25,
      alignment: "place-self-center self-start",
    },
    {
      x: 22434,
      y: 1989,
      w: 1371.71,
      h: 1963.82,
      alignment: "place-self-center self-end",
    },
    {
      x: 24384,
      y: 2160,
      w: 1490.69,
      h: 2134.15,
      alignment: "place-self-center self-start",
    },
    {
      x: 29782,
      y: 2152,
      w: 1503.6,
      h: 2152.63,
      alignment: "place-self-center self-start",
    },
    {
      x: 29782,
      y: 2152,
      w: 1503.6,
      h: 2152.63,
      alignment: "place-self-center self-start",
    },
    {
      x: 29782,
      y: 2152,
      w: 1503.6,
      h: 2152.63,
      alignment: "place-self-center self-start",
    },
  ],
  [DeviceEnums.mobile]: [
    {
      x: 886,
      y: 1715,
      w: 780,
      h: 1688,
      alignment: "place-self-center self-start",
    },
    {
      x: 4820,
      y: 2160,
      w: 945.16,
      h: 2045.43,
      alignment: "place-self-center self-start",
    },
    {
      x: 9988,
      y: 2160,
      w: 890.24,
      h: 1926.57,
      alignment: "place-self-center self-end",
    },
    {
      x: 13466,
      y: 2160,
      w: 982.69,
      h: 2126.63,
      alignment: "place-self-center self-start",
    },
    {
      x: 18898,
      y: 2160,
      w: 982.69,
      h: 2126.63,
      alignment: "place-self-center self-start",
    },
    {
      x: 22840,
      y: 2060,
      w: 925.33,
      h: 2002.51,
      alignment: "place-self-center self-end",
    },
    {
      x: 27642,
      y: 2160,
      w: 943.78,
      h: 2042.45,
      alignment: "place-self-center self-start",
    },
    {
      x: 30073,
      y: 2160,
      w: 943.78,
      h: 2042.45,
      alignment: "place-self-center self-start",
    },
    {
      x: 30073,
      y: 2160,
      w: 943.78,
      h: 2042.45,
      alignment: "place-self-center self-start",
    },
    {
      x: 30073,
      y: 2160,
      w: 943.78,
      h: 2042.45,
      alignment: "place-self-center self-start",
    },
  ],
};

export const devicesDimensions = {
  [DeviceEnums.desktop]: {
    available: ["landscape"],
    width: 1440,
    height: 1024,
  },
  [DeviceEnums.tablet]: {
    available: ["landscape", "portrait"],
    width: 1194,
    height: 834,
  },
  [DeviceEnums.mobile]: {
    available: ["landscape", "portrait"],
    width: 852,
    height: 393,
  },
};

export const imageDimensions = { w: 32948, h: 2160 };
